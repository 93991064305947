.app-header {
    background-color: #fff;
    padding: 12px 24px;

    &.has-footer {
        padding-bottom: 0;
    }

    .ant-page-header {
        padding-top: 4px;
        padding-left: 0px;
        padding-bottom: 12px;

        &-heading-left {
            .anticon {
                font-size: 32px;
                color: #000000;
            }
        }
    }

    &__footer {
        .ant-tabs {
            > .ant-tabs-nav {
                margin: 0;

                &::before {
                    border: none;
                }
            }

            .ant-tabs-tab {
                padding-top: 8 px;
                padding-bottom: 8 px;
                font-size: 16px;
            }
        }
    }
}
