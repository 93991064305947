.date-expenses {
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
    }

    &__report {
        display: flex;
        flex-direction: column;
    }

    &__expenses {
        flex-basis: auto;
        align-self: top;
    }

    &__total {
        margin: 10px;
    }

    &__chart {
        height: 550px;
        flex-grow: 1;
        flex-shrink: 1;
        display: block;

        @media (max-width: 576px) {
            display: none;
        }
    }
}
