.report-total-amount {
    &__totals {
        margin-left: auto;
        width: 120px;
        text-align: right;
        padding-right: 24px;
        display: flex;
        flex-direction: column;

        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            overflow: hidden;
        }
    }
}
