.category-list {
    margin: 24px;

    &__category-card {
        height: 100px;
        transition: background 0.3s;

        :hover {
            background: #fafafa;
        }

        &__meta {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__create-button {
        width: 100%;
        height: 100px !important;
    }
}
