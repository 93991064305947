.wrapper {
    flex: 1 1;
    padding: 32px 0 24px;
}

.login-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    padding: 150px 0;
    overflow: auto;

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        line-height: 44px;

        a {
            text-decoration: none;
        }

        &__logo {
            width: 44px;
            height: 44px;
            margin-right: 16px;
            vertical-align: top;

            img {
                width: 100%;
            }
        }

        &__title {
            position: relative;
            top: 2px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 600;
            font-size: 33px;
        }
    }

    &__desc {
        text-align: center;
        margin-top: 12px;
        margin-bottom: 40px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
    }

    &__main {
        width: 328px;
        margin: 0 auto;
    }
}
