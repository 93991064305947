.category-expense-item {
    margin: 10px;

    &__category {
        display: flex;
    }

    &__collapse-btn {
        margin-right: 10px;
    }

    &__name {
        width: 250px;
    }

    &__total {
        width: 120px;
        text-align: right;
        padding-right: 24px;
        display: flex;
        flex-direction: column;

        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            overflow: hidden;
        }
    }

    &__subcategories {
        margin-left: 34px;
    }
}
