.logo {
    float: left;
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 16px;
    line-height: 32px;
    cursor: pointer;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 32px;
    }

    img {
        height: 32px;
        vertical-align: middle;
    }

    h1 {
        margin: 0 0 0 12px;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
    }
}

.header {
    &__profile {
        float: right;

        &__account {
            display: flex;
            align-items: center;
            height: 64px;
            padding: 0 12px;
            cursor: pointer;
            transition: all 0.3s;

            &__avatar {
                margin-right: 8px !important;
                color: #001529 !important;
                background: hsla(0, 0%, 100%, 0.85) !important;
            }
        }

        &__name {
            color: #fff;
        }
    }
}
